:root {
  --fs-default: 14px;
  --fs-1: 34px;
  --fs-2: 30px;
  --fs-3: 25px;
  --fs-4: 19px;
  --fs-5: 18px;
  --fs-6: 17px;
  --fs-7: 15px;
  --gap: 15px;
  --gap-m: calc(-1 * var(--gap));
  --favorite-color: #4b41bc;
  --toast-info-text: rgba(0, 0, 0, 0.8);
  --toast-info-background: rgb(211, 211, 211);
  --toast-warning-background: white;
}
@media (max-width: 1199px) {
  :root {
    --fs-2: 25px;
    --fs-3: 20px;
    --fs-5: 14px;
  }
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-Regular.eot");
  src:
    url("../fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/OpenSans-Regular.woff2") format("woff2"),
    url("../fonts/OpenSans-Regular.woff") format("woff"),
    url("../fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-Italic.eot");
  src:
    url("../fonts/OpenSans-Italic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/OpenSans-Italic.woff2") format("woff2"),
    url("../fonts/OpenSans-Italic.woff") format("woff"),
    url("../fonts/OpenSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-SemiBold.eot");
  src:
    url("../fonts/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/OpenSans-SemiBold.woff2") format("woff2"),
    url("../fonts/OpenSans-SemiBold.woff") format("woff"),
    url("../fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-SemiBoldItalic.eot");
  src:
    url("../fonts/OpenSans-SemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/OpenSans-SemiBoldItalic.woff2") format("woff2"),
    url("../fonts/OpenSans-SemiBoldItalic.woff") format("woff"),
    url("../fonts/OpenSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-Bold.eot");
  src:
    url("../fonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/OpenSans-Bold.woff2") format("woff2"),
    url("../fonts/OpenSans-Bold.woff") format("woff"),
    url("../fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-BoldItalic.eot");
  src:
    url("../fonts/OpenSans-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/OpenSans-BoldItalic.woff2") format("woff2"),
    url("../fonts/OpenSans-BoldItalic.woff") format("woff"),
    url("../fonts/OpenSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-ExtraBold.eot");
  src:
    url("../fonts/OpenSans-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/OpenSans-ExtraBold.woff2") format("woff2"),
    url("../fonts/OpenSans-ExtraBold.woff") format("woff"),
    url("../fonts/OpenSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-ExtraBoldItalic.eot");
  src:
    url("../fonts/OpenSans-ExtraBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/OpenSans-ExtraBoldItalic.woff2") format("woff2"),
    url("../fonts/OpenSans-ExtraBoldItalic.woff") format("woff"),
    url("../fonts/OpenSans-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
html,
body {
  height: 100%;
}

html {
  font-size: 12px;
}

body {
  background: #fff;
  font-style: normal;
  font-weight: normal;
  font-size: var(--fs-default);
  line-height: 1.2;
  font-family: "Open Sans", Arial, sans-serif;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
}
@media (max-width: 1199px) {
  body.is-menu-opened {
    overflow: hidden;
  }
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.main a:not(.normal-a) {
  text-decoration: underline;
  color: gray;
}
.main a:hover {
  text-decoration: none;
}

input,
button,
select,
textarea {
  font-weight: normal;
  font-family: "Open Sans", Arial, sans-serif;
  outline: none;
}

input,
button {
  -webkit-appearance: none;
  appearance: none;
}

picture,
time {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

form {
  width: 100%;
}

.wb-index {
  padding: 100px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
}
.wb-index a {
  width: 100%;
  padding: 10px 0;
  display: block;
  font-size: 18px;
  text-align: center;
}

#site {
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

svg,
.icon {
  width: 1em;
  height: 1em;
  display: inline-block;
  color: inherit;
  fill: currentColor;
}
.icon_size-20px {
  width: 20px;
  height: 20px;
}
.icon_margin-left-10px {
  margin-left: 10px;
}

.container {
  max-width: 1284px;
  margin: 0 auto;
  padding: 0 var(--gap);
}

.row {
  margin: 0 calc(-1 * var(--gap));
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.row__col {
  max-width: 100%;
  min-height: 1px;
  padding: 0 var(--gap);
  -ms-flex: 1;
  flex: 1;
}
.row__col--4 {
  max-width: 33.333333333%;
  -ms-flex-preferred-size: 33.333333333%;
  flex-basis: 33.333333333%;
}
.row__col--6 {
  max-width: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

.img-cover {
  display: block;
  position: relative;
  overflow: hidden;
}
.img-cover img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.text-overflow {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-clamp--2 {
  -webkit-line-clamp: 2;
}

.color-gray {
  color: #808080;
}
.color-pink {
  background-color: rgb(241, 183, 156);
}
.public-table {
  border: 1px solid black;
  border-collapse: collapse;
}
.public-table td {
  border: 1px solid black;
  padding: 5px 20px;
  text-align: center;
  font-weight: bold;
}

.area-click,
.profile-aside__nav-link,
.profile-aside__header-btn-toggle,
.profile-aside__header-login,
.profile-settings-card__b-header-settings,
.intro__body-close,
.footer-nav__link,
.header-nav__link,
.contacts-media__link,
.slick-dots li button,
.btn-copy {
  --indent: -5px;
  position: relative;
}
.area-click:before,
.profile-aside__nav-link:before,
.profile-aside__header-btn-toggle:before,
.profile-aside__header-login:before,
.profile-settings-card__b-header-settings:before,
.intro__body-close:before,
.footer-nav__link:before,
.header-nav__link:before,
.contacts-media__link:before,
.slick-dots li button:before,
.btn-copy:before {
  content: "";
  position: absolute;
  top: var(--indent);
  right: var(--indent);
  bottom: var(--indent);
  left: var(--indent);
}

.btn-reset,
.profile-aside__header-btn-toggle,
.intro__body-close,
.header__toggle,
.header__menu-btn-close,
.search-form__btn,
.slick-dots li button,
.btn-copy {
  background: transparent;
  margin: 0;
  padding: 0;
  font-size: 0;
  outline: none;
  border: none;
  border-radius: 0;
}

.btn-copy {
  width: 22px;
  height: 22px;
  display: block;
  border-radius: 4px;
  transition: all 0.24s;
  transition-property: opacity;
  cursor: pointer;
}
.btn-copy:hover {
  text-decoration: none;
}
@media (min-width: 1024px) {
  .btn-copy:hover {
    opacity: 0.4;
  }
}
.btn-copy__icon {
  width: 100%;
  height: 100%;
  display: block;
}

.btn-group__inner {
  margin: -8px;
  display: -ms-flexbox;
  display: flex;
}
.btn-group__item {
  margin: 8px;
}

.input-profit {
  position: relative;
  padding-right: 16px;
}
.input-profit:after {
  background-position: 50%;
  background-size: contain;
  background-color: transparent;
  background-repeat: no-repeat;
  width: 12px;
  height: 28px;
  margin-top: -14px;
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
}
.input-profit--plus:after {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='28' viewBox='0 0 12 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M-7.86805e-07 10L6 -2.62268e-07L12 10L7.03923 10L7.03923 28L4.96077 28L4.96077 10L-7.86805e-07 10Z' fill='%235FBD00'/%3E%3C/svg%3E");
}
.input-profit--minus:after {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='28' viewBox='0 0 12 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 18L6 28L-8.51591e-07 18L4.96077 18L4.96077 -3.07695e-07L7.03923 -2.16842e-07L7.03923 18L12 18Z' fill='%23D20000'/%3E%3C/svg%3E");
}
.group-btn-text__inner {
  margin: -8px;
  display: -ms-flexbox;
  display: flex;
}
.group-btn-text__item {
  margin: 8px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.group-btn-text__item-title {
  margin: 0 12px 0 0;
  font-size: var(--fs-7);
  color: #404040;
}
.group-btn-text__item-el {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

td.cell-vertical-middle {
  vertical-align: middle;
}

.checkbox-label {
  display: block;
  font-size: var(--fs-7);
  color: #000;
  cursor: pointer;
  position: relative;
}
.checkbox-label__control {
  position: absolute;
  display: none;
  left: -9999px;
}
.checkbox-label__inner {
  display: block;
  position: relative;
}
.checkbox-label__inner-icon {
  width: 20px;
  height: 20px;
  display: block;
  border: 1px solid currentColor;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
}
.checkbox-label__inner-icon:after {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.2356 5.92254C14.3039 5.85208 14.3853 5.79611 14.4752 5.75789C14.5651 5.71968 14.6616 5.7 14.7591 5.7C14.8566 5.7 14.9531 5.71968 15.043 5.75789C15.1329 5.79611 15.2144 5.85208 15.2826 5.92254C15.5686 6.21496 15.5726 6.68749 15.2926 6.98496L9.3796 14.0577C9.31247 14.1323 9.231 14.1922 9.14018 14.2339C9.04936 14.2755 8.9511 14.298 8.85141 14.2999C8.75172 14.3018 8.6527 14.2831 8.5604 14.2449C8.46809 14.2067 8.38446 14.1499 8.3146 14.0779L4.7166 10.3888C4.57784 10.2456 4.50011 10.053 4.50011 9.85251C4.50011 9.65197 4.57784 9.45942 4.7166 9.31623C4.78485 9.24578 4.8663 9.1898 4.95619 9.15159C5.04608 9.11338 5.1426 9.0937 5.2401 9.0937C5.33761 9.0937 5.43413 9.11338 5.52402 9.15159C5.61391 9.1898 5.69536 9.24578 5.7636 9.31623L8.8156 12.4458L14.2156 5.9448C14.2218 5.93698 14.2285 5.92954 14.2356 5.92254Z' fill='black'/%3E%3C/svg%3E%0A");
  width: 20px;
  height: 20px;
  margin: -10px 0 0 -10px;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 0.34s;
  transition-property: visibility, opacity;
}
.checkbox-label__inner-text {
  padding: 0 0 0 30px;
  display: block;
  line-height: 20px;
}
.checkbox-label__control:not(:checked)
  ~ .checkbox-label__inner
  .checkbox-label__inner-icon:after {
  visibility: hidden;
  opacity: 0;
}
@media (min-width: 1024px) {
  .checkbox-label:hover
    .checkbox-label__control:not(:checked)
    ~ .checkbox-label__inner
    .checkbox-label__inner-icon:after {
    visibility: visible;
    opacity: 0.4;
  }
}

.checkbox-btn-label {
  display: block;
  cursor: pointer;
  position: relative;
}
.checkbox-btn-label__control {
  position: absolute;
  display: none;
  left: -9999px;
}
.checkbox-btn-label__inner {
  min-height: 52px;
  padding: 16px 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
  font-size: var(--fs-7);
  color: #404040;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 7px;
  transition: all 0.34s;
  transition-property: background, color, border-color, box-shadow;
}
.checkbox-btn-label--theme-white .checkbox-btn-label__inner {
  background: #fff;
}
.checkbox-btn-label--theme-light .checkbox-btn-label__inner {
  background: transparent;
  border-color: currentColor;
}
.checkbox-btn-label--theme-white
  .checkbox-btn-label__control:checked
  ~ .checkbox-btn-label__inner,
.checkbox-btn-label--theme-light
  .checkbox-btn-label__control:checked
  ~ .checkbox-btn-label__inner {
  background: #404040;
  color: #fff;
  border-color: #404040;
}
@media (min-width: 1024px) {
  .checkbox-btn-label--theme-white:hover
    .checkbox-btn-label__control:not(:checked)
    ~ .checkbox-btn-label__inner,
  .checkbox-btn-label--theme-light:hover
    .checkbox-btn-label__control:not(:checked)
    ~ .checkbox-btn-label__inner {
    box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.17);
  }
}
.capabilities-item {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  display: inline-block;
}
.capabilities-item--type-1 {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_24_27)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.50896 5.00893V14.9912H20.4913V5.00893H3.50896ZM1.49126 5.00008C1.49126 3.89063 2.39065 2.99123 3.50011 2.99123H20.5001C21.6096 2.99123 22.509 3.89063 22.509 5.00008V15.0001C22.509 16.1095 21.6096 17.0089 20.5001 17.0089H3.50011C2.39065 17.0089 1.49126 16.1095 1.49126 15.0001V5.00008Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.99091 7.00006C9.99091 6.44288 10.4426 5.99121 10.9998 5.99121H12.9998C13.5569 5.99121 14.0086 6.44288 14.0086 7.00006C14.0086 7.55723 13.5569 8.00891 12.9998 8.00891H10.9998C10.4426 8.00891 9.99091 7.55723 9.99091 7.00006ZM0.990915 20.0001C0.990915 19.4429 1.44259 18.9912 1.99976 18.9912H21.9998C22.5569 18.9912 23.0086 19.4429 23.0086 20.0001C23.0086 20.5572 22.5569 21.0089 21.9998 21.0089H1.99976C1.44259 21.0089 0.990915 20.5572 0.990915 20.0001Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_24_27'%3E%3Crect width='22' height='18' fill='white' transform='translate(1 3)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}
.capabilities-item--type-2 {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.0909 3.00818L7.90909 3C7.00909 3 6.27273 3.73636 6.27273 4.63636V19.3636C6.27273 20.2636 7.00909 21 7.90909 21H16.0909C16.9909 21 17.7273 20.2636 17.7273 19.3636V4.63636C17.7273 3.73636 16.9909 3.00818 16.0909 3.00818ZM16.0909 17.7273H7.90909V6.27273H16.0909V17.7273Z' fill='black'/%3E%3C/svg%3E%0A");
}
.capabilities-item--type-3 {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.9127 4.58084C9.60578 4.58084 9.31143 4.70277 9.09441 4.91979C8.87739 5.13681 8.75547 5.43116 8.75547 5.73808V7.82534C8.75547 8.33898 8.33908 8.75537 7.82543 8.75537C7.31179 8.75537 6.8954 8.33898 6.8954 7.82534V5.73808C6.8954 4.93784 7.21329 4.17038 7.77915 3.60452C8.345 3.03867 9.11246 2.72078 9.9127 2.72078H20.7665C21.5667 2.72078 22.3342 3.03867 22.9 3.60452C23.4659 4.17038 23.7838 4.93784 23.7838 5.73808V18.2617C23.7838 19.0619 23.4659 19.8294 22.9 20.3952C22.3342 20.9611 21.5667 21.279 20.7665 21.279H9.9127C9.11246 21.279 8.345 20.9611 7.77915 20.3952C7.21329 19.8294 6.8954 19.0619 6.8954 18.2617V16.1744C6.8954 15.6608 7.31179 15.2444 7.82543 15.2444C8.33908 15.2444 8.75547 15.6608 8.75547 16.1744V18.2617C8.75547 18.5686 8.87739 18.8629 9.09441 19.0799C9.31143 19.297 9.60578 19.4189 9.9127 19.4189H20.7665C21.0734 19.4189 21.3677 19.297 21.5848 19.0799C21.8018 18.8629 21.9237 18.5686 21.9237 18.2617V5.73808C21.9237 5.43116 21.8018 5.13681 21.5848 4.91979C21.3677 4.70277 21.0734 4.58084 20.7665 4.58084H9.9127Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.1775 7.16761C12.5407 6.80441 13.1295 6.80441 13.4927 7.16761L17.6673 11.3421C18.0305 11.7053 18.0305 12.2942 17.6673 12.6574L13.4927 16.8319C13.1295 17.1951 12.5407 17.1951 12.1775 16.8319C11.8143 16.4687 11.8143 15.8799 12.1775 15.5167L14.7643 12.9298H1.14643C0.632784 12.9298 0.216394 12.5134 0.216394 11.9998C0.216394 11.4861 0.632784 11.0697 1.14643 11.0697H14.7643L12.1775 8.48288C11.8143 8.11968 11.8143 7.53081 12.1775 7.16761Z' fill='black'/%3E%3C/svg%3E%0A");
}

.services-price {
  background: #fff;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  min-height: 70px;
  padding: 12px 35px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: normal;
  font-size: var(--fs-3);
  text-align: center;
  border-radius: 7px;
  white-space: nowrap;
}
@media (max-width: 1199px) {
  .services-price {
    min-height: 54px;
  }
}

.logo {
  display: inline-block;
  font-size: 42px;
  line-height: 1;
  color: #fff;
  text-align: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  transition: all 0.24s;
  transition-property: opacity;
}
.logo:hover {
  text-decoration: none;
}
@media (min-width: 1024px) {
  .logo:hover {
    opacity: 0.6;
  }
}
@media (max-width: 1199px) {
  .logo {
    font-size: 34px;
  }
}
.logo:hover {
  text-decoration: none;
}
.logo__title {
  margin: 0 0 4px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 800;
}
.logo__title-text {
  margin-right: 8px;
  display: block;
}
.logo__title-text-accent {
  padding: 3px 11px 5px;
  display: block;
  border-radius: 6px;
}
.logo--accent-white .logo__title-text-accent {
  background: #404040;
}
.logo--accent-dark .logo__title-text-accent {
  background: #fff;
  color: #404040;
}
.logo__subtitle {
  display: block;
  font-weight: bold;
  font-size: 33.3333333333%;
}

.btn {
  max-width: 100%;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.24s;
  transition-property: background, color, border-color;
  user-select: none;
}
.feed-section__header .btn {
  margin-right: 70px;
}
.feed-section__header .btn.is-active {
  background: #404040;
}
.btn:hover {
  text-decoration: none;
}
.btn--size-sm {
  min-height: 40px;
  padding: 10px 22px;
  font-size: var(--fs-7);
}
.btn--size-md {
  min-height: 46px;
  padding: 11px 22px;
  font-size: var(--fs-7);
}
.btn--size-st {
  min-height: 54px;
  padding: 14px 40px;
  font-size: var(--fs-6);
}
.btn--size-lg {
  min-height: 76px;
  padding: 16px 62px;
  font-size: var(--fs-5);
}
@media (max-width: 767px) {
  .btn--size-lg {
    min-height: 58px;
    padding: 13px 39px;
    font-size: 16px;
  }
  .feed-section__header .btn {
    margin-right: 10px;
  }
  .btn--size-sm {
    padding: 10px;
  }
}
.btn--theme-dark {
  background: #404040;
  color: #fff;
}

.p-dropdown {
  height: 40px;
  border-radius: 7px !important;
}
.p-dropdown-label {
  font-size: var(--fs-7) !important;
}
.p-dropdown-item {
  font-size: var(--fs-7);
}

.p-selectbutton {
  border-radius: 7px;
  padding: 7px;
}
.p-selectbutton .p-button {
  color: #404040;
  height: 40px;
  min-width: 100px;
  border-radius: 7px !important;
}
.p-selectbutton .p-highlight {
  background: #404040;
  color: #fff;
}

.p-chip {
  border-radius: 7px;
  background: #fff;
  height: 50px;
  min-width: 52px;
  width: 100%;
  padding: 0px 20px;
  margin-right: 15px !important;
  display: flex;
  align-items: center;
  opacity: 0.5;
  transition: 200ms;
  user-select: none;
}
.p-chip.disabled {
  opacity: 0.3;
  cursor: default !important;
}
.p-chip:not(.disabled):hover {
  opacity: 1;
}
.p-chip:last-child {
  margin-right: 0px !important;
}
.p-chip.active {
  opacity: 1;
}

.p-chip .icon {
  margin: 0px;
  margin-right: 10px;
}
@media (max-width: 1024px) {
  .p-chip {
    padding: 0px 5px;
    justify-content: center;
  }
  .p-chip .icon {
    margin-right: 0px !important;
  }
}

.p-paginator-page {
  font-size: var(--fs-7) !important;
}

@media (min-width: 1024px) {
  .btn--theme-dark:hover {
    background: #000;
  }
}
.btn--theme-white {
  background: #fff;
  font-weight: 600;
  color: #9e9e9e;
}
@media (min-width: 1024px) {
  .btn--theme-white:hover {
    background: #eaeaea;
  }
}
.btn--theme-white-accent {
  background: #fff;
  font-weight: 600;
  color: #404040;
}
@media (min-width: 1024px) {
  .btn--theme-white-accent:hover {
    background: #eaeaea;
  }
}
.btn--theme-gray {
  background: #959595;
  font-weight: normal;
  color: #fff;
}
@media (min-width: 1024px) {
  .btn--theme-gray:hover {
    background: #000;
  }
}
.btn--theme-simple {
  background: #fff;
  font-size: var(--fs-default);
  color: #000;
  border-color: currentColor;
}
.btn--theme-favorite {
  background-color: var(--favorite-color);
  color: #fff;
  font-size: var(--fs-default);
  transition: 200ms;
}
.btn--theme-favorite:hover {
  opacity: 0.9;
}
.btn--theme-disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.3);
  border-color: rgba(0, 0, 0, 0.3);
  background-color: transparent;
}
.btn--theme-selection-blue {
  color: var(--favorite-color);
  border-color: var(--favorite-color);
  background-color: transparent;
}

.event-card__href:hover {
  text-decoration: none;
}
.event-card__href:hover button {
  background-color: rgba(75, 65, 188, 0.2);
}

@media (min-width: 1024px) {
  .btn--theme-simple:hover {
    background: #eaeaea;
  }
}
.btn__icon-wrapper {
  width: 22px;
  height: 22px;
  -ms-flex: 0 0 22px;
  flex: 0 0 22px;
  position: relative;
}
.btn__icon-wrapper--ltr {
  margin-right: 8px;
}
.btn__icon-wrapper--rtl {
  margin-left: 8px;
}
.btn__icon {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.12s;
  transition-property: visibility, opacity;
}
.btn.is-active .btn__icon--normal,
.btn:not(.is-active) .btn__icon--active {
  visibility: hidden;
  opacity: 0;
}

.input-el {
  background: #fff;
  width: 100%;
  min-height: 40px;
  padding: 8px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-weight: normal;
  font-size: var(--fs-7);
  line-height: 1.35;
  color: #000;
  border: 1px solid currentColor;
  border-radius: 7px;
  outline: none;
  box-shadow: 0 0 0 transparent;
  transition: all 0.24s;
  transition-property: background, box-shadow;
  position: relative;
}
.input-el:disabled {
  background: #eaeaea;
}
@media (max-width: 500px) {
  .event-card__cell {
    padding: 5px 2px !important;
  }
  .input-el {
    font-size: 14px;
    padding: 2px 3px;
  }
  .p-dropdown {
    padding: 2px 3px !important;
  }
  .p-dropdown-label {
    font-size: 14px;
  }
}
@media (min-width: 1024px) {
  .input-el:hover,
  .input-el:focus {
    background: #eaeaea;
  }
  .input-el:focus {
    box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.17);
  }
}
.input-el--size-md {
  min-height: 54px;
  padding: 12px;
}
.input-el--is-readonly {
  background: #eaeaea;
  border-color: transparent;
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.input-el--theme-white {
  border-color: transparent;
}
@media (min-width: 1024px) {
  .input-el--theme-white:hover {
    box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.17);
  }
}
.input-el::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.input-el:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.input-el::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.input-el::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
a.input-el__link {
  margin-right: 20px;
  text-decoration: underline;
  -webkit-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
  pointer-events: initial;
  transition: all 0.4s;
  transition-property: box-shadow;
  color: #000;
}
.input-el__link:hover {
  text-decoration: none;
}
.input-el__btn-copy {
  -ms-flex: 0 0 var(--btn-size);
  flex: 0 0 var(--btn-size);
  margin-left: auto;
  pointer-events: auto;
}

.p-dropdown {
  background: #959595;
  width: 100%;
  min-height: 40px;
  padding: 8px 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  line-height: 1.55;
  color: #fff;
  border: none;
  border-radius: 7px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  transition: all 0.24s;
}
.p-dropdown--theme-white-accent {
  background: #fff;
  color: #404040;
  border: 1px solid #404040;
}

.event-card .p-dropdown {
  background: transparent;
  color: #000;
  border: 1px solid #000;
  padding: 7px 8px;
}
.p-dropdown:hover {
  background: rgba(128, 128, 128, 0.2);
  outline: none;
}

.event-card .p-dropdown:hover {
  background: #eaeaea;
}
.p-dropdown-trigger-icon {
  font-size: 10px;
}
.p-dropdown-items-wrapper {
  background: #fff;
  margin-top: 4px;
  border: none;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  border-radius: 7px !important;
}
.p-dropdown-items {
  padding: 4px !important;
  scrollbar-color: #d1d5db transparent;
  scrollbar-width: thin;
}
.p-dropdown-items .p-highlight {
  background: #f3f4f6;
  text-decoration: none;
  color: #000;
}
.p-dropdown-items::-webkit-scrollbar {
  width: 4px;
}
.p-dropdown-items::-webkit-scrollbar-track {
  border: solid 6px transparent;
}
.p-dropdown-items::-webkit-scrollbar-thumb {
  background-color: #d1d5db;
  border-radius: 18px;
  border: 6px solid transparent;
}
.p-dropdown-item {
  background: transparent;
  width: 100%;
  padding: 10px 12px;
  display: block;
  font-size: 14px;
  line-height: 1.2;
  color: #000;
  text-align: left;
  border: none;
  border-radius: 4px;
  outline: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.24s;
  transition-property: background;
}
.p-dropdown-item:hover {
  background: #f3f4f6;
  text-decoration: none;
  color: #000;
}

.select2-container .select2-selection--single {
  background: #959595;
  width: 100%;
  min-height: 40px;
  padding: 8px 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  line-height: 1.55;
  color: #fff;
  border: none;
  border-radius: 7px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  transition: all 0.24s;
  transition-property: background;
}
.select2-container .select2-selection--single:hover,
.select2-container .select2-selection--single:focus {
  background: #808080;
  outline: none;
}
.select2-container .select2-selection--single:disabled,
.select2-container .select2-selection--single.disabled {
  background: #f9fafb !important;
  border-color: #9e9e9e !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.select2-container--select2-container--light .select2-selection--single {
  background: transparent;
  color: #000;
  border: 1px solid currentColor;
}

.select2-container--select2-container--light
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  color: #000;
  border: 1px solid currentColor;
}

.select2-container--select2-container--light
  .select2-selection--single
  .select2-selection__rendered {
  color: #000 !important;
}

.select2-container--select2-container--light
  .select2-selection--single
  .select2-selection__arrow
  b {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.30589 2.6121L4.99956 6.59705L0.693224 2.6121C0.616284 2.54076 0.512998 2.50082 0.405445 2.50082C0.297893 2.50082 0.194607 2.54076 0.117667 2.6121C0.080413 2.64675 0.0508167 2.68811 0.0306119 2.73375C0.0104071 2.7794 0 2.82841 0 2.87793C0 2.92744 0.0104071 2.97645 0.0306119 3.0221C0.0508167 3.06774 0.080413 3.1091 0.117667 3.14375L4.69886 7.38389C4.77931 7.45834 4.88721 7.5 4.99956 7.5C5.11191 7.5 5.21981 7.45834 5.30026 7.38389L9.88145 3.14457C9.91897 3.10989 9.94879 3.06843 9.96915 3.02264C9.98951 2.97684 10 2.92763 10 2.87793C10 2.82822 9.98951 2.77901 9.96915 2.73322C9.94879 2.68742 9.91897 2.64596 9.88145 2.61128C9.80451 2.53994 9.70122 2.5 9.59367 2.5C9.48612 2.5 9.38283 2.54076 9.30589 2.6121Z' fill='black'/%3E%3C/svg%3E%0A") !important;
}

.select2-container.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-radius: 7px;
}

.select2-container .select2-selection--single {
  height: auto;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding: 0 5px 0 0;
  color: #fff;
  line-height: inherit;
}

.select2-container .select2-selection--single .select2-selection__arrow {
  width: initial;
  height: initial;
  margin: 0 0 0 auto;
  position: relative;
  top: 0;
  right: 0;
}

.select2-container .select2-selection--single .select2-selection__arrow b {
  background: 50% no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.30589 2.6121L4.99956 6.59705L0.693224 2.6121C0.616284 2.54076 0.512998 2.50082 0.405445 2.50082C0.297893 2.50082 0.194607 2.54076 0.117667 2.6121C0.080413 2.64675 0.0508167 2.68811 0.0306119 2.73375C0.0104071 2.7794 0 2.82841 0 2.87793C0 2.92744 0.0104071 2.97645 0.0306119 3.0221C0.0508167 3.06774 0.080413 3.1091 0.117667 3.14375L4.69886 7.38389C4.77931 7.45834 4.88721 7.5 4.99956 7.5C5.11191 7.5 5.21981 7.45834 5.30026 7.38389L9.88145 3.14457C9.91897 3.10989 9.94879 3.06843 9.96915 3.02264C9.98951 2.97684 10 2.92763 10 2.87793C10 2.82822 9.98951 2.77901 9.96915 2.73322C9.94879 2.68742 9.91897 2.64596 9.88145 2.61128C9.80451 2.53994 9.70122 2.5 9.59367 2.5C9.48612 2.5 9.38283 2.54076 9.30589 2.6121Z' fill='white'/%3E%3C/svg%3E%0A");
  width: 10px;
  height: 10px;
  display: block;
  margin: 0;
  border: none;
  position: relative;
  top: 0;
  left: 0;
  transition: all 0.24s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property:
    transform,
    -webkit-transform;
}

.select2-container.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  -webkit-transform: scale(-1);
  transform: scale(-1);
}

.select2-dropdown {
  background: #fff;
  margin-top: 4px;
  border: none;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  border-radius: 7px !important;
}
.select2-dropdown.select2-dropdown--above {
  margin-top: -4px;
}

.select2-container .select2-results > .select2-results__options,
.select2-results__options {
  max-height: 208px;
}

.select2-results__options {
  padding: 4px;
  scrollbar-color: #d1d5db transparent;
  scrollbar-width: thin;
}
.select2-results__options::-webkit-scrollbar {
  width: 4px;
}
.select2-results__options::-webkit-scrollbar-track {
  border: solid 6px transparent;
}
.select2-results__options::-webkit-scrollbar-thumb {
  background-color: #d1d5db;
  border-radius: 18px;
  border: 6px solid transparent;
}

.select2-results__option {
  background: transparent;
  width: 100%;
  padding: 10px 12px;
  display: block;
  font-size: 14px;
  line-height: 1.2;
  color: #000;
  text-align: left;
  border: none;
  border-radius: 4px;
  outline: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.24s;
  transition-property: background;
}
.select2-results__option + .select2-results__option {
  margin-top: 4px;
}

.select2-container .select2-results__option--disabled {
  color: rgba(0, 0, 0, 0.8);
}

.select2-container
  .select2-results__option--highlighted.select2-results__option--selectable,
.select2-container .select2-results__option--selected {
  background: #f3f4f6;
  text-decoration: none;
  color: #000;
}

.p-toast-message {
  background-color: white;
  border-radius: 10px;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.p-toast-message.p-toast-message-warning {
  background-color: var(--toast-warning-background);
}
.p-toast-message.p-toast-message-info {
  background-color: var(--toast-info-background);
}

.p-toast-message-text {
  margin-left: 20px;
}
.p-toast-summary {
  font-weight: bold;
  font-size: 16px;
  color: var(--toast-info-text);
}
.p-toast-detail {
  margin-top: 5px;
  color: var(--toast-info-text);
}
.p-toast-message-icon {
  font-size: 26px;
  font-weight: normal;
  color: var(--toast-info-text);
}
.p-toast-icon-close-icon {
  font-size: 16px;
  color: var(--toast-info-text);
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots {
  margin: 0;
  padding: 0 var(--gap);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  list-style-type: none;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.slick-dots li {
  margin: 2px;
  padding: 0;
}
.slick-dots li.slick-active button {
  background: #404040;
}
@media (min-width: 1024px) {
  .slick-dots li:not(.slick-active):hover button {
    background: #404040;
  }
}
.slick-dots li button {
  background: #d9d9d9;
  width: 14px;
  height: 14px;
  display: block;
  position: relative;
  border-radius: 50%;
  transition: all 0.34s;
  transition-property: background;
  cursor: pointer;
}
.pagination__list {
  margin: -4px;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  list-style-type: none;
}
.p-paginator-page,
.p-paginator-first,
.p-paginator-prev,
.p-paginator-next,
.p-paginator-last {
  background: #959595 !important;
  height: 38px;
  min-width: 38px;
  margin: 4px !important;
  padding: 8px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: var(--fs-6);
  color: #fff;
  text-align: center;
  border-radius: 8px;
  transition: all 0.4s;
  transition-property: background, color;
}
@media (max-width: 450px) {
  .p-paginator-page,
  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    min-width: 30px;
    height: 30px;
    margin: 2px !important;
  }
}
@media (min-width: 1024px) {
  .p-paginator-page:hover {
    background: #404040 !important;
  }
}
.p-paginator-pages .p-highlight {
  background: #404040 !important;
  pointer-events: none;
}
.p-disabled {
  opacity: 0.4;
  pointer-events: none;
}
.p-paginator-first,
.p-paginator-prev,
.p-paginator-next,
.p-paginator-last {
  background: transparent !important;
  color: #1a1a1a;
}
@media (min-width: 1024px) {
  .p-paginator-first:hover,
  .p-paginator-prev:hover,
  .p-paginator-next:hover,
  .p-paginator-last:hover {
    color: #fff;
    background: #404040 !important;
  }
}

.position--relative {
  position: relative !important;
}
.position--relative .icon {
  position: absolute;
  top: 60%;
}
.position--relative .event-card__btn-invisible {
  position: absolute;
  top: 60%;
  left: 30%;
}
@media (min-width: 500px) {
  .position--relative .event-card__btn-invisible {
    left: 35%;
  }
}
@media (min-width: 700px) {
  .position--relative .event-card__btn-invisible {
    left: 40%;
  }
}
@media (min-width: 1000px) {
  .position--relative .event-card__btn-invisible {
    left: 42%;
  }
}

.event-card {
  position: relative;
  background: #fff;
  padding: 7px;
  font-size: var(--fs-default);
  border-radius: 7px;
  border: 3px solid transparent;
}
.event-card__removed {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 50px;
  background-color: rgba(64, 64, 64, 0.8);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  z-index: 1;
}

.is-new {
  border: 3px solid #2bb90e;
}
.is-favorite {
  border: 3px solid var(--favorite-color);
}
.is-removed {
  border: 3px solid red;
}

.event-card__icon {
  min-width: 25px;
  min-height: 25px;
  display: inline-block;
  object-fit: contain;
  vertical-align: middle;
  margin: 0px 5px;
  transition: 200ms;
  cursor: pointer;
}
.event-card__icon:hover {
  opacity: 0.7;
}
@media (max-width: 450px) {
  .event-card__icon {
    margin: 0px 1px;
  }
}

@media (max-width: 1199px) {
  /* .event-card {
    border-radius: 0;
  } */
  .is-favorite {
    border: none;
    border-top: 3px solid var(--favorite-color);
    border-bottom: 3px solid var(--favorite-color);
  }
}
.event-card__table {
  width: 100%;
  font-weight: normal;
  text-align: left;
  border-collapse: collapse;
}
.event-card__table-tr {
  display: -ms-flexbox;
  display: flex;
  align-items: flex-start;
}
.event-card__table-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.event-card__table-footer {
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1199px) {
  .event-card__table-footer {
    flex-direction: column;
    align-items: flex-start;
  }
  .event-card__table-footer .event-card__table-tr {
    width: 100%;
  }
  .event-card__table-tr .btn {
    min-height: 35px;
    width: auto;
    padding: 0px 9px;
  }
}

.event-card__url {
  text-decoration: underline;
}
.event-card__url:hover {
  text-decoration: none;
}

.event-card__btn-invisible {
  width: auto;
  padding: 0px;
  height: auto;
  border: none;
  background: transparent;
}

.event-card__cell {
  margin: 0;
  padding: 8px 4px;
  font-weight: normal;
  -ms-flex: 1;
  flex: 1;
  vertical-align: top;
}
.event-card__cell--1,
.event-card__cell--3,
.event-card__cell--4,
.event-card__cell--5 {
  white-space: nowrap;
}
.event-card__cell--1 {
  max-width: 12.5%;
  -ms-flex-preferred-size: 12.5%;
  flex-basis: 12.5%;
  display: flex;
  flex-direction: column;
}
.event-card__cell--2 {
  max-width: 40%;
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
}
.event-card__cell--3 {
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  max-width: 20%;
  -ms-flex-preferred-size: 20%;
  flex-basis: 20%;
  justify-content: flex-start;
}
.event-card__cell--4 {
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  max-width: 27.5%;
  -ms-flex-preferred-size: 27.5%;
  justify-content: flex-start;
}
.event-card__cell--flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.event-card__cell--flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.event-card__cell--flex-row-end {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.event-card__cell--5 {
  max-width: 60%;
  -ms-flex-preferred-size: 60%;
  flex-basis: 60%;
}
.event-card__cell--8 {
  max-width: 80%;
  -ms-flex-preferred-size: 80%;
  flex-basis: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 1199px) {
  .event-card__cell--5 {
    max-width: initial;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .event-card__cell--1,
  .event-card__cell--3,
  .event-card__cell--4,
  .event-card__cell--7 {
    justify-content: center;
  }
}
.event-card__cell--7 {
  max-width: 15%;
  -ms-flex-preferred-size: 15%;
  flex-basis: 15%;
  display: flex;
  flex-direction: column;
}
.event-card__table-header .event-card__cell {
  padding-bottom: 4px;
}
.event-card__table-footer .event-card__cell {
  padding-top: 4px;
}
.event-card__table-footer .event-card__table-tr:first-child .event-card__cell {
  padding-top: 24px;
  padding-bottom: 20px;
}
.event-card__title {
  margin: 0;
  font-weight: 600;
  font-size: var(--fs-6);
}
@media (max-width: 1199px) {
  .event-card__title {
    font-weight: normal;
    font-size: 14px;
  }
}
.event-card__padding-left--15 {
  padding-left: 15px;
}
.event-card__padding-left--20 {
  padding-left: 20px;
}
.event-card__padding-left--25 {
  padding-left: 25px;
}
.event-card__title-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.event-card__description {
  margin: 0;
  font-size: var(--fs-7);
}
@media (max-width: 1199px) {
  .event-card__description {
    font-size: 14px;
    color: #000;
  }
}
.event-card__game-info {
  margin: 0;
}
.event-card__info {
  margin: 0;
  font-weight: normal;
  font-size: var(--fs-default);
}
.event-card__bo-name {
  margin: 0;
}
.event-card__name {
  margin: 0 0 8px;
}
.event-card__bo-bet {
  margin: 0 0 8px;
}
.event-card__bo-profit {
  max-width: 150px;
  margin: 0 30px 0 0;
}
.event-card__time {
  margin: 0;
}
.event-card__btn-list {
  margin: -5px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.event-card__btn-list span {
  margin: 0px 5px;
}
.event-card__btn-list--center {
  justify-content: center;
}
@media (max-width: 1199px) {
  .event-card__btn-list {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}
.event-card__btn-list-item {
  margin: 5px;
  padding-right: 5px;
  padding-left: 5px;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
@media (max-width: 1199px) {
  .event-card__btn-list-item {
    margin: 0px;
    border: none;
  }
}
@media (max-width: 1199px) {
  .event-card__btn-list-item .btn__icon-wrapper {
    margin: 0 !important;
  }
}
.event-card__bet-game {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
  color: var(--fs-7);
}
.event-card__bet-game-icon {
  width: auto;
  max-width: 20px;
  height: auto;
  max-height: 20px;
  margin-right: 14px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.event-card__data-list {
  margin: -5px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-size: var(--fs-7);
}
.event-card__data-list-col {
  max-width: 25%;
  min-height: 1px;
  padding: 5px 2px;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}
.event-card__data-list-col--size-20 {
  max-width: 20%;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
}
.event-card__data-list-col--size-30 {
  max-width: 30%;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
}
.event-card .select2-container {
  width: 100% !important;
}
.event-card .select2-container .select2-selection--single {
  padding-right: 7px;
  padding-left: 7px;
}

.contacts-media__list {
  margin: -4px;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  list-style-type: none;
  -ms-flex: wrap;
  flex: wrap;
}
.contacts-media__item {
  margin: 4px;
  padding: 0;
}
.contacts-media__link {
  width: 32px;
  height: 32px;
  display: block;
  font-size: 0;
  line-height: 32px;
  text-align: center;
  transition: all 0.24s;
  transition-property: color;
}
@media (min-width: 1024px) {
  .contacts-media__link:hover {
    color: #959595;
  }
}
.contacts-media__icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  object-fit: contain;
  vertical-align: middle;
}

.search-form {
  position: relative;
}
.search-form__input {
  width: 100%;
  padding-right: 40px;
  padding-left: 16px;
}
.search-form__input::-ms-clear,
.search-form__input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
.search-form__input::-webkit-search-decoration,
.search-form__input::-webkit-search-cancel-button,
.search-form__input::-webkit-search-results-button,
.search-form__input::-webkit-search-results-decoration {
  display: none;
}
.search-form__btn {
  width: 42px;
  height: 100%;
  font-size: 22px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 7px 7px 0;
  cursor: pointer;
}
.search-form__btn:after {
  width: 0;
  height: 100%;
  content: "";
  display: inline-block;
  vertical-align: middle;
}
.search-form__btn .icon {
  display: inline-block;
  vertical-align: middle;
}

.bookmaker-table {
  font-size: var(--fs-7);
}
.bookmaker-table__row {
  margin: 0 -10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.bookmaker-table__row--header {
  margin-bottom: 20px;
}
.bookmaker-table__row--body:not(:last-child) {
  margin-bottom: 10px;
}
.bookmaker-table__col {
  max-width: 100%;
  padding: 0 10px;
  -ms-flex: 1;
  flex: 1;
}
.bookmaker-table__col--size-3 {
  max-width: 25%;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
}
.bookmaker-table__title {
  font-weight: bold;
}
.bookmaker-table__capabilities-list {
  min-width: 100px;
  margin: 0 -4px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.bookmaker-table__capabilities-list-item {
  margin: 0 4px;
}
.bookmaker-table__language {
  min-width: 168px;
}

.header-nav__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
@media (min-width: 1024px) {
  .header-nav__list {
    padding: 0 5px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
}
.header-nav__item {
  margin: 0;
  padding: 0;
}
@media (max-width: 1024px) {
  .header-nav__item + .header-nav__item {
    margin-top: 18px;
  }
}
.header-nav__link {
  display: block;
  font-weight: 600;
  font-size: var(--fs-7);
  transition: all 0.34s;
  transition-property: opacity;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .header-nav__link {
    font-size: var(--fs-5);
    text-align: center;
  }
}
.header-nav__link:hover {
  text-decoration: none;
}
@media (min-width: 1024px) {
  .header-nav__link:hover {
    opacity: 0.7;
  }
}
.header-nav__dropdown {
  width: 130px;
  margin-bottom: 20px;
}
@media (min-width: 1024px) {
  .header-nav__dropdown {
    margin-right: 10px;
    margin-bottom: 0;
    padding: 8px;
  }
}

.header {
  background: #9e9e9e;
  padding: 10px 0;
  color: #fff;
  position: relative;
}
.header__container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
}
.header__logo {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.header__menu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
}
@media (max-width: 1024px) {
  .header__menu {
    background: #404040;
    width: 100%;
    height: 100%;
    padding: 35px 30px 55px;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    transition: all 0.34s;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1001;
  }
}
.header__menu-btn-close {
  width: 32px;
  height: 32px;
  color: #fff;
  position: absolute;
  top: 24px;
  right: 20px;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .header__menu-btn-close {
    display: none;
  }
}
.header__menu-btn-close .icon {
  width: 100%;
  height: 100%;
  display: block;
}
@media (min-width: 1024px) {
  .header__nav {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}
@media (max-width: 1024px) {
  .header__nav {
    width: 100%;
    margin: auto 0;
    padding: 18px 0;
  }
}
.header__action {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
@media (min-width: 1024px) {
  .header__action {
    margin-left: auto;
  }
}
@media (max-width: 1024px) {
  .header__action {
    -ms-flex-order: -1;
    order: -1;
  }
}
@media (max-width: 1024px) {
  .header__action .btn-group__inner {
    min-width: 178px;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .header__action .btn-group__inner .btn {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .header__action .btn {
    background: #fff;
    font-weight: 600;
    color: #404040;
  }
}
.header__toggle {
  width: 44px;
  height: 32px;
  margin-left: auto;
  display: block;
  color: #fff;
  position: relative;
  cursor: pointer;
}
.header__toggle-line {
  background: currentColor;
  height: 4px;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  right: 0;
  border-radius: 2px;
  -webkit-transform-origin: right center;
  transform-origin: right center;
}
.header__toggle-line:nth-child(1) {
  width: 100%;
  -webkit-transform: translateY(-15px);
  transform: translateY(-15px);
}
.header__toggle-line:nth-child(2) {
  width: 66.66%;
}
.header__toggle-line:nth-child(3) {
  width: 100%;
  -webkit-transform: translateY(15px);
  transform: translateY(15px);
}
.header__country-option {
  display: flex; 
  align-items: center;
  column-gap: 8px;
}

.footer-nav {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
@media (max-width: 767px) {
  .footer-nav {
    text-align: center;
  }
}
.footer-nav__item {
  margin: 0;
  padding: 0;
}
.footer-nav__item:not(:last-child) {
  margin-bottom: 10px;
}
.footer-nav__link {
  font-size: var(--fs-6);
  color: rgba(255, 255, 255, 0.8);
  box-shadow: inset 0 -1px 0 transparent;
  transition: all 0.24s;
  transition-property: box-shadow;
}
.footer-nav__link:hover {
  text-decoration: none;
}
@media (min-width: 1024px) {
  .footer-nav__link:hover {
    box-shadow: inset 0 -1px 0 currentColor;
  }
}
.footer-nav__item:first-child .footer-nav__link {
  margin-bottom: 6px;
  font-weight: 600;
  font-size: var(--fs-4);
  color: #fff;
}

.footer {
  background: #404040;
  margin-top: auto;
  padding: 26px 0;
  color: #fff;
}
@media (max-width: 767px) {
  .footer {
    padding: 20px 0 40px;
  }
}
.footer__inner {
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 767px) {
  .footer__inner {
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.footer__col--logo {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
@media (max-width: 767px) {
  .footer__col--logo {
    margin-bottom: 34px;
    -ms-flex-order: -2;
    order: -2;
  }
}
.footer__col--nav {
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.footer__col--contacts {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
@media (max-width: 767px) {
  .footer__col--contacts {
    width: 100%;
    margin-bottom: 40px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-order: -1;
    order: -1;
  }
}
.footer__nav-list {
  margin: 0 -10px;
  padding: 0 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media (max-width: 1199px) {
  .footer__nav-list {
    margin-bottom: -30px;
    padding: 0 20px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .footer__nav-list {
    padding-right: 0;
    padding-left: 0;
  }
}
.footer__nav-list-item {
  padding: 0 10px;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
@media (max-width: 1199px) {
  .footer__nav-list-item {
    max-width: 50%;
    margin-bottom: 30px;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
}
@media (max-width: 767px) {
  .footer__nav-list-item {
    max-width: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}
.footer__contacts-btn {
  width: 100%;
  margin: 0 0 20px;
  font-size: var(--fs-6);
}
@media (max-width: 767px) {
  .footer__contacts-btn {
    width: auto;
  }
}
.footer__contacts-media {
  -ms-flex-pack: center;
  justify-content: center;
}
.footer__contacts-media .contacts-media__list {
  -ms-flex-pack: center;
  justify-content: center;
}
.footer__copyright {
  max-width: 800px;
  margin: 40px auto 0;
  line-height: 1.35;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}
@media (max-width: 767px) {
  .footer__copyright {
    margin-top: 30px;
  }
}
.footer__copyright a {
  box-shadow: 0 1px 0 currentColor;
  transition: all 0.24s;
  transition-property: color;
}
.footer__copyright a:hover {
  text-decoration: none;
}
@media (min-width: 1024px) {
  .footer__copyright a:hover {
    color: #fff;
  }
}
.footer__copyright p {
  margin: 0 0 20px;
}
.footer__copyright :last-child {
  margin-bottom: 0;
}

.main {
  display: block;
}

.intro {
  padding: 25px 0px 0px;
}
@media (max-width: 767px) {
  .intro {
    padding: 15px 0 20px;
  }
  .event-card__table-tr {
    align-items: center;
  }
}
.intro__appeal {
  margin: 0 0 25px;
  font-size: var(--fs-7);
  display: flex;
}
.intro__appeal p {
  margin: 0px;
  background: #eaeaea;
  line-height: 1.35;
  border-radius: 7px;
  padding: 11px;
  width: 100%;
}
.intro__switch {
  border-radius: 7px;
  background-color: #404040;
  margin-left: 10px;
  width: 45px;
  max-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0px;
}
.intro__icon {
  width: 15px;
  height: 7px;
}
@media (max-width: 767px) {
  .intro__appeal {
    margin-bottom: 15px;
  }
}
.intro__body {
  padding: 50px 60px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  box-shadow: 0 4px 26px rgba(0, 0, 0, 0.16);
  border-radius: 7px;
  position: relative;
  margin-bottom: 40px;
}
@media (max-width: 991px) {
  .intro__body {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: center;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .intro__body {
    padding: 15px;
  }
}
.intro__body-close {
  width: 38px;
  height: 38px;
  position: absolute;
  top: 28px;
  right: 28px;
  transition: all 0.24s;
  transition-property: opacity;
  cursor: pointer;
}
@media (max-width: 767px) {
  .intro__body-close {
    width: 20px;
    height: 20px;
    top: 16px;
    right: 16px;
  }
}
@media (min-width: 1024px) {
  .intro__body-close:hover {
    opacity: 0.6;
  }
}
.intro__body-close svg {
  width: 100%;
  height: 100%;
  display: block;
}
.intro__body-content {
  margin-right: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
@media (max-width: 767px) {
  .intro__body-content {
    margin-right: 30px;
  }
}
.intro__body-content-title {
  margin: 0 0 40px;
  font-size: var(--fs-1);
}
@media (max-width: 991px) {
  .intro__body-content-title {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .intro__body-content-title {
    margin-bottom: 10px;
    font-size: var(--fs-4);
  }
}
@media (min-width: 992px) {
  .intro__body-content-btn {
    margin-top: auto;
  }
}
.intro__body-picture {
  max-width: 430px;
  margin-right: 50px;
  -ms-flex: 0 0 38%;
  flex: 0 0 38%;
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 991px) {
  .intro__body-picture {
    margin: 0 auto 18px;
  }
}
@media (max-width: 767px) {
  .intro__body-picture {
    max-width: 64%;
  }
}
.intro__body-picture img {
  width: 100%;
  height: auto;
  display: block;
}
@media (max-width: 767px) {
  .intro__body-picture img {
    max-width: 430px;
    margin: 0 auto;
  }
}

.feed-section {
  background: #eaeaea;
  padding: 30px 0;
}
@media (max-width: 1199px) {
  .feed-section {
    padding: 10px 0;
  }
}
.feed-section__header {
  height: auto;
  margin: 0px;
  padding: 10px 0px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  overflow-x: auto;
}
.feed-section__header-btn-profit {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.feed-section__list {
  margin: 0 0 20px;
}
/* @media (max-width: 1199px) {
  .feed-section__list {
    margin: 0 var(--gap-m) 10px;
  }
} */
.feed-section__list-card:not(:last-child) {
  margin-bottom: 30px;
}
@media (max-width: 1199px) {
  .feed-section__list-card:not(:last-child) {
    margin-bottom: 10px;
  }
}

.profile-settings-card {
  background: #eaeaea;
  padding: 30px;
  border-radius: 7px;
}
.profile-settings-card__header {
  margin: 0 0 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
}
.profile-settings-card__title,
.profile-settings-card__b-header-title,
.profile-settings-card__b-header-settings {
  font-size: var(--fs-3);
}
.profile-settings-card__title,
.profile-settings-card__b-header-title {
  font-weight: bold;
}
.profile-settings-card__title {
  margin: 0 15px 0 0;
}
.profile-settings-card__b-header {
  margin: 0 0 45px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
}
.profile-settings-card__b-header-title {
  margin: 0 15px 0 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.profile-settings-card__b-header-settings {
  margin: 0 auto 0 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  color: rgba(0, 0, 0, 0.5);
  transition: all 0.34s;
  transition-property: color, box-shadow;
}
@media (min-width: 1024px) {
  .profile-settings-card__b-header-settings {
    box-shadow: inset 0 -1px 0 transparent;
  }
}
.profile-settings-card__b-header-settings:hover {
  text-decoration: none;
}
@media (min-width: 1024px) {
  .profile-settings-card__b-header-settings:hover {
    color: #000;
    box-shadow: inset 0 -1px 0 currentColor;
  }
}
.profile-settings-card__b-header-search {
  max-width: 400px;
  margin-left: 15px;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.profile-settings-card__btn {
  margin-left: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.profile-settings-card__subscription {
  background: #fff;
  width: 100%;
  margin: 0 0 30px;
  font-size: var(--fs-7);
  text-align: left;
  border-collapse: collapse;
  border-radius: 7px;
}
.profile-settings-card__subscription th,
.profile-settings-card__subscription td {
  padding: 8px 18px;
}
.profile-settings-card__subscription th:first-child,
.profile-settings-card__subscription td:first-child {
  padding-left: 30px;
}
.profile-settings-card__subscription th:last-child,
.profile-settings-card__subscription td:last-child {
  padding-right: 30px;
}
.profile-settings-card__subscription th {
  font-weight: bold;
  font-size: var(--fs-6);
}
.profile-settings-card__subscription thead tr:first-child th {
  padding-top: 20px;
}
.profile-settings-card__subscription tbody:last-child tr:last-child td,
.profile-settings-card__subscription tfoot tr:last-child td {
  padding-bottom: 20px;
}
.profile-settings-card__subscription-stop {
  margin-left: 10%;
  box-shadow: inset 0 -1px 0 currentColor;
  transition: all 0.34s;
  transition-property: opacity;
}
.profile-settings-card__subscription-stop:hover {
  text-decoration: none;
}
@media (min-width: 1024px) {
  .profile-settings-card__subscription-stop:hover {
    opacity: 0.5;
  }
}
.profile-settings-card__subscription-description {
  margin: 0;
  line-height: 1.4;
}
.profile-settings-card__promocode {
  max-width: 730px;
  display: -ms-flexbox;
  display: flex;
}
.profile-settings-card__promocode-input {
  margin-right: 14px;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.profile-settings-card__promocode-btn {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.profile-settings-card__edit {
  max-width: 680px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.profile-settings-card__edit-input {
  margin-bottom: 8px;
  -ms-flex: 1;
  flex: 1;
}

.input__wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
}

.input__wrapper--w-100 {
  width: 100%;
}

.profile-settings-card__edit-input--w-50 {
  max-width: calc(50% - 7px);
  -ms-flex-preferred-size: calc(50% - 7px);
  flex-basis: calc(50% - 7px);
}
.profile-settings-card__edit-input--w-100 {
  width: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
.profile-aside__header {
  margin: 0 0 40px;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  row-gap: 8px;
}
.profile-aside__header-title {
  margin-right: 0.9em;
  font-weight: bold;
  font-size: var(--fs-2);
  line-height: inherit;
}
.profile-aside__header-login {
  font-size: var(--fs-5);
  color: rgba(0, 0, 0, 0.5);
  transition: all 0.34s;
  transition-property: color, box-shadow;
  margin: 0px;
}
@media (max-width: 1000px) {
  .profile-aside__header {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (min-width: 1024px) {
  .profile-aside__header-login {
    box-shadow: inset 0 -1px 0 transparent;
    margin-bottom: 20px;
  }
}
.profile-aside__header-login:hover {
  text-decoration: none;
}
@media (min-width: 1024px) {
  .profile-aside__header-login:hover {
    color: #000;
    box-shadow: inset 0 -1px 0 currentColor;
  }
}
.profile-aside__header-btn-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  font-size: 44px;
  background-color: #404040;
  border-radius: 7px;
}
.profile-aside__header-btn-toggle .icon {
  display: block;
  filter: invert();
}
.profile-aside__nav {
  font-size: var(--fs-5);
}
.profile-aside__nav-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.profile-aside__nav-item {
  margin: 0;
  padding: 0;
}
.profile-aside__nav-item:not(:last-child) {
  margin-bottom: 32px;
}
.profile-aside__nav-link {
  display: block;
  transition: all 0.4s;
  transition-property: color;
}
.profile-aside__nav-link.is-active {
  font-weight: bold;
}
.profile-aside__nav-link:hover {
  text-decoration: none;
}

.profile__title {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  font-size: 25px;
}
.profile__title h2 {
  margin: 0px;
}

.profile-main__account-list-card:not(:last-child) {
  margin-bottom: 30px;
}

.profile-layout {
  padding: 68px 0;
}
.profile-layout__container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}
.profile-layout__aside {
  width: 22.2%;
  margin-right: 20px;
  -ms-flex: 0 0 22.2%;
  flex: 0 0 22.2%;
}
.profile-layout__main {
  -ms-flex-positive: 1;
  flex-grow: 1;
}
@media (max-width: 800px) {
  .profile-layout {
    padding: 15px 0px 60px;
  }
  .profile-layout__container {
    flex-direction: column;
  }
  .profile-layout__aside {
    width: 100%;
    padding: 15px 20px;
    margin-bottom: 30px;
    background-color: #eaeaea;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
  }
}

.tariffs-slider__slide {
  padding: 0 15px;
  height: 100%;
}
@media (max-width: 991px) {
  .tariffs-slider__slide {
    padding-right: 7px;
    padding-left: 7px;
  }
}
.tariffs-slider__card {
  height: 100%;
}

.tariffs-section {
  padding: 55px 0;
}
@media (max-width: 1199px) {
  .tariffs-section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.main__title {
  margin: 0 0 30px;
  font-weight: bold;
  font-size: var(--fs-2);
  text-align: center;
}
@media (max-width: 1199px) {
  .main__title {
    margin-bottom: 20px;
  }
}
.tariffs-build__list {
  margin: 0 0 60px;
}
.tariffs-build__card:not(:last-child) {
  margin-bottom: 30px;
}
.tariffs-build__card-title {
  margin: 0 0 15px;
  font-weight: 600;
  font-size: var(--fs-6);
  text-align: center;
}
.tariffs-build__card-row {
  margin: 0 -8px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
}
.tariffs-build__card-row-col {
  padding: 0 8px;
}
.tariffs-build__card-label {
  min-width: 162px;
}
.tariffs-build__appeal {
  max-width: 654px;
  margin: 0 auto;
  padding: 20px 20px 40px;
  font-size: var(--fs-7);
  border: 1px solid #404040;
  border-radius: 7px;
}
.tariffs-build__appeal-header {
  margin: 0 0 45px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.tariffs-build__appeal-header-col--info {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.tariffs-build__appeal-header-col--description {
  margin: 0;
  -ms-flex-item-align: end;
  align-self: flex-end;
}
.tariffs-build__appeal-header-title {
  margin: 0 0 15px;
  font-weight: bold;
  font-size: var(--fs-3);
}
.tariffs-build__appeal-header-description {
  min-height: 54px;
  margin: 0;
  padding: 0 5px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
}
.tariffs-build__appeal-header-price {
  background: #fff;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  min-width: 150px;
  min-height: 54px;
  padding: 8px 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: normal;
  font-size: var(--fs-5);
  text-align: center;
  border-radius: 7px;
  white-space: nowrap;
}
.tariffs-build__appeal-info {
  margin: 0 -10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.tariffs-build__appeal-info li {
  margin-right: 10px;
  margin-left: 10px;
}

.tariffs-build-section {
  background: #eaeaea;
  padding: 40px 0 80px;
}
.tariffs-build-section__title {
  margin: 0 0 45px;
  font-weight: bold;
  font-size: var(--fs-2);
  text-align: center;
}
.tariffs-faq-section {
  padding-bottom: 80px;
}
.tariffs-faq-section__payments {
  width: 100%;
  max-width: 716px;
  margin: 0 auto 60px;
  font-size: 0;
  text-align: center;
}
.tariffs-faq-section__payments img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: top;
}
.tariffs-faq-section__title {
  margin-bottom: 50px;
  width: 100%;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  line-height: 34px;
}
.tariffs-faq-section__info {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.16);
  border-radius: 7px;
  display: flex;

  padding: 0px 40px;

  align-items: center;
  justify-content: flex-start;
  height: 175px;

  margin-bottom: 50px;
}
.tariffs-faq-section__info-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tariffs-faq-section__info-item .tariffs-faq-section__info-icon {
  margin-right: 15px;
}
.tariffs-faq-section__content {
  font-size: var(--fs-5);
  line-height: 1.35;
}
.tariffs-faq-section__content > :first-child {
  margin-top: 0;
}
.tariffs-faq-section__content > :last-child {
  margin-bottom: 0;
}
.tariffs-faq-section__content h3 {
  margin: 1.6em 0 0.8em;
  font-size: var(--fs-3);
}
.tariffs-faq-section__content p {
  color: rgba(0, 0, 0, 0.5);
}

/* MY STYLE */
.event__container {
  width: 100%;
}
.page-title {
  margin: 40px auto;
}
.page-title h1 {
  margin: 0px;
  text-align: center;
}
.event-title {
  display: flex;
  margin-bottom: 40px;
}
.event-title-col {
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-right: 1px solid gray;
}
.event-title-col:first-child {
  padding-left: 0px;
}
.event-title__item {
  width: 100%;
  margin-bottom: 10px;
}
.event-title__item h3 {
  margin: 0px;
}
.event-title__item:last-child {
  margin: 0px;
}

.text-align--center {
  text-align: center;
}

.event-back {
  width: 35px;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms;
}
.event-back:hover {
  background-color: #eaeaea;
}

.icon-event-back {
  width: 13px;
  height: 25px;
}

.p-tooltip {
  position: absolute;
}
.p-tooltip .p-tooltip-text {
  white-space: pre-line;
  word-break: break-word;
}
.p-tooltip .p-tooltip-text {
  background: #495057;
  color: #ffffff;
  padding: 0.75rem 0.75rem;
  border-radius: 6px;
}
.p-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #495057;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #495057;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #495057;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #495057;
}

.p-tooltip-right .p-tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -0.25rem;
  border-width: 0.25em 0.25em 0.25em 0;
}
.p-tooltip-left .p-tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -0.25rem;
  border-width: 0.25em 0 0.25em 0.25rem;
}
.p-tooltip-top .p-tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -0.25rem;
  border-width: 0.25em 0.25em 0;
}
.p-tooltip-bottom .p-tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -0.25rem;
  border-width: 0 0.25em 0.25rem;
}

.progress-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.progress-spinner span {
  margin-top: 10px;
  font-size: 18px;
}

.p-overlaypanel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
}
.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-overlaypanel-content {
  padding: 1.25rem;
}

.popup {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.popup .popup__title {
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
}
.popup .popup__content {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  max-width: 300px;
  flex-wrap: wrap;
}
.popup .popup__content .popup__col {
  width: auto;
  display: flex;
  margin-right: 10px;
  margin-bottom: 5px;
}
.popup .popup__content .popup__col:last-child {
  margin-right: 0px;
}
.popup .popup__content input {
  margin: 0px 5px;
  border: none;
  border-bottom: 1px solid gray;
  width: 50px;
  text-align: center;
  outline: none;
}
.flex-row {
  display: flex;
}
.flex-row--col-start {
  align-items: flex-start;
}
.width-max {
  width: 100%;
}
.margin-bottom-m {
  margin-bottom: 25px;
}
.margin-none-child form {
  margin: 0px;
}
.margin-none-child form input,
.margin-none-child form button {
  margin: 0px !important;
}

.p-carousel-items-container {
  align-items: stretch;
  width: 90%;
}
.p-carousel-container button.p-disabled {
  display: none;
}
.p-carousel-indicators {
  display: none !important;
  margin-top: 30px !important;
}
.p-carousel-indicators .p-carousel-indicator {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  background-color: #d9d9d9;
  border-radius: 50%;
}
.p-carousel-indicators .p-carousel-indicator:last-child {
  margin-right: 0px;
}
.p-carousel-indicators .p-carousel-indicator button {
  width: 100%;
  background-color: transparent;
}
.p-carousel-indicators .p-carousel-indicator.p-highlight {
  background-color: #404040;
}

.p-carousel-container .p-carousel-prev-icon,
.p-carousel-container .p-carousel-next-icon {
  display: none;
}
.p-carousel-container .p-carousel-prev,
.p-carousel-container .p-carousel-next {
  display: none;
  width: 30px;
  background-color: #eaeaea;
  height: 412px;
}
.p-carousel-container .p-carousel-prev {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.p-carousel-container .p-carousel-next {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.place__filter {
  margin-bottom: 20px;
}

.width--max {
  width: 100%;
}
.display--flex {
  display: flex;
}
.row--center {
  justify-content: center;
}
.row-horizontal--center {
  align-items: center;
}

.pad-r--16px {
  padding-right: 16px;
}

.active-animation {
  transition: 300ms;
}
.active-animation:active {
  transform: rotate(150deg);
}

.p-checkbox {
  display: inline-flex;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: bottom;
  position: relative;
  width: 22px;
  height: 22px;
  margin-right: 10px;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #404040;
}
.p-checkbox-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-checkbox .p-checkbox-box {
  border: 1px solid #404040;
  background: #ffffff;
  width: 22px;
  height: 22px;
  color: #495057;
  border-radius: 4px;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 14px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  background: #404040;
}
.p-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.app-button {
  width: auto;
  min-width: 150px;
  height: 55px;
  border-radius: 7px;
  border: none;
  background-color: #404040;
  color: #fff;
  padding: 0px 20px;
}
.app-button:hover {
  opacity: 0.8;
}

.margin-bottom--25 {
  margin-bottom: 25px !important;
}
.margin-right--10 {
  margin-right: 10px;
}
.padding-rl--40 {
  padding: 0px 40px;
}
.padding-rl--20 {
  padding: 0px 20px;
}

.font-size--18 {
  font-size: 18px !important;
}
.font-size--14 {
  font-size: 14px !important;
}
.font-size--13 {
  font-size: 13px !important;
}
.font-size--12 {
  font-size: 12px !important;
}
.color-light-gray {
  color: rgba(0, 0, 0, 0.5) !important;
}

.p-error:not(input) {
  color: red !important;
}
.p-success:not(input) {
  color: green !important;
}
.p-info:not(input) {
  color: #888 !important;
}

input.p-error {
  border: 1px solid red !important;
}

.forks-not-found {
  width: 100%;
  height: 415px;
  background: #ffffff;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.16);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 16px;
}
.forks-not-found h2 {
  margin: 0px;
  margin-bottom: 10px;
  font-size: 25px;
  text-align: center;
}
.forks-not-found p {
  margin: 0px;
  font-size: 17px;
  text-align: center;
}

/* TARIFFS */
.tariffs-slider {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}
.tariffs-slider .tariffs-card {
  flex: 0.8 1 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #eaeaea;
  border-radius: 7px;
  padding: 30px 50px;
}

.tariffs-slider .tariffs-card p {
  font-size: 15px;
  margin: 0px;
  margin-bottom: 10px;
}

.tariffs-slider .tariffs-card .tariffs-card__title {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 15px;
}
.tariffs-slider .tariffs-card .tariffs-card_types-sport {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 90%;
  margin-bottom: 20px;
}
.tariffs-slider .tariffs-card .tariffs-card_types-sport__type {
  width: auto;
  flex: 1 1 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 7px;
  background-color: #fff;
  height: 70px;
}
.tariffs-slider .tariffs-card .tariffs-card_types-sport__type.active {
  background-color: #404040;
  color: #fff;
}
.tariffs-slider .tariffs-card .tariffs-card_types-sport__plus {
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.tariffs-slider .tariffs-card .tariffs-card__info {
  width: 90%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 60px;
  margin-bottom: 20px;
  height: 85px;
}
.tariffs-slider .tariffs-card .tariffs-card__info .p-dropdown {
  background-color: #fff;
  color: #000;
  height: 70px;
  text-align: center;
}

.tariffs-slider .tariffs-card .tariffs-card__period {
  flex: 1 1 40%;
}
.tariffs-slider .tariffs-card .tariffs-card__profit {
  flex: 1 1 40%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
}
.tariffs-slider .tariffs-card .tariffs-card__description {
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}
.tariffs-slider .tariffs-card .tariffs-card__price-info {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}
.tariffs-slider .tariffs-card .tariffs-card__price {
  width: auto;
  flex: 1 1 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 7px;
  background-color: #fff;
  height: 55px;
}
.tariffs-slider .tariffs-card .tariffs-card__button {
  width: auto;
  flex: 1 1 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 7px;
  background-color: #404040;
  color: #fff;
  height: 55px;
  border: none;
  cursor: pointer;
}
.tariffs-slider .tariffs-card .tariffs-card__button:hover {
  opacity: 0.8;
}
.tariffs-slider .tariffs-card .tariffs-card__prolongation {
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

.app-input {
  width: 100%;
  height: 40px;
  border-radius: 7px;
  color: #404040;
  border: 1px solid #404040;
  padding: 8px 16px;
  font-size: 15px;
}

.backdrop {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 5;
}
.modal-window {
  width: 90%;
  margin: 50px auto;
  padding: 40px 60px;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.16);
  position: relative;
}
.modal-window--size-middle {
  width: 50%;
  max-width: 600px;
}
.modal-window .modal-window__title {
  top: 0px;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 700;
}
.modal-window .modal-window__close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  background-image: url("../assets/icon-close.png");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.modal-window .modal-window__row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
}
.modal-window .modal-window__column {
  flex: 1 1 20%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.modal-window .modal-window_frame {
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-height: 75px;
}
.modal-window .modal-window_frame .modal-window_frame__title {
  font-weight: 700;
  font-size: 15px;
}

.filter-params {
  display: flex;
  align-items: center;
}

/* CUSTOM CHECKBOX */
.checkbox input[type="checkbox"] + label {
  display: inline-block;
  position: relative;
  padding-left: 32px;
  line-height: 20px;
  cursor: pointer;
}
.checkbox input[type="checkbox"] + label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 22px;
  height: 22px;
  border: 1px solid black;
  background-color: #ffffff;
  border-radius: 4px;
}
.checkbox input[type="checkbox"]:checked + label:before {
  background-color: #404040;
}
.checkbox input[type="checkbox"] + label:after {
  content: "";
  position: absolute;
}

.checkbox input[type="checkbox"]:checked + label:after {
  left: 4px;
  top: 6px;
  width: 13px;
  height: 8px;
  border-radius: 1px;
  border-left: 2px solid #f3f3f3;
  border-bottom: 2px solid #f3f3f3;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media (max-width: 991px) {
  .tariffs-slider .tariffs-card .tariffs-card_types-sport {
    flex-direction: column;
    gap: 5px;
  }
  .tariffs-slider .tariffs-card .tariffs-card_types-sport__type {
    min-height: 50px;
    width: 100%;
    min-width: 300px;
  }
  .tariffs-slider .tariffs-card .tariffs-card__info {
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .tariffs-slider .tariffs-card .tariffs-card__period {
    width: 100%;
  }
  .tariffs-slider .tariffs-card .tariffs-card__price-info {
    flex-direction: column;
  }
  .tariffs-slider .tariffs-card .tariffs-card__price {
    min-height: 50px;
    width: 100%;
  }
  .tariffs-slider .tariffs-card .tariffs-card__button {
    width: 100%;
    min-height: 50px;
  }
  .tariffs-slider .tariffs-card .tariffs-card__info .p-dropdown {
    width: 100%;
    height: 50px;
  }
  .tariffs-slider .tariffs-card p {
    text-align: center;
  }

  .modal-window--size-middle {
    width: 100%;
  }
  .modal-window {
    padding: 10px 15px;
    margin: calc(10% / 2) auto;
    height: 90%;
    overflow: auto;
  }
  .modal-window .modal-window__close {
    top: 20px;
    right: 20px;
  }
  .modal-window .modal-window__row {
    flex-direction: column;
    align-items: stretch;
  }
  .modal-window .modal-window__column {
    flex: 1 1 100%;
  }
}

@media (max-width: 767px) {
  .forks-not-found {
    height: auto;
  }

  .forks-not-found h2 {
    font-size: 20px;
  }

  .forks-not-found p {
    font-size: 14px;
  }
}

@media (min-width: 576px) {
  .xs-visible {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .sm-visible {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .md-visible {
    display: none !important;
  }
}
@media (min-width: 1025px) {
  .lg-visible {
    display: none !important;
  }
}
@media (max-width: 1199px) {
  .lg-none {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .md-none {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .sm-none {
    display: none !important;
  }
}
@media (max-width: 576px) {
  .xs-none {
    display: none !important;
  }
}

@media (max-width: 800px) {
  .container {
    padding: 0px 10px;
  }
  .profile-settings-card {
    padding: 20px 15px;
  }

  .input__wrapper {
    max-width: 100%;
  }

  .profile-aside__nav {
    display: none;
  }
  .profile-aside__header {
    margin: 0px;
  }
  .profile-settings-card__header {
    flex-direction: column;
    align-items: center;
  }
  .profile-settings-card__btn {
    margin: 0px;
    margin-top: 20px;
    padding: 16px 12px;
  }
  .profile-settings-card form {
    display: flex;
    flex-direction: column;
  }
  .profile-settings-card form input {
    max-width: 100%;
    margin-bottom: 10px;
  }
  .profile-settings-card form button {
    margin-top: 10px;
  }

  .profile-settings-card__subscription {
    background-color: transparent;
  }
  .profile-settings-card__subscription thead {
    display: none;
  }
  .profile-settings-card__subscription tr {
    display: block;
  }
  .profile-settings-card__subscription td {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    padding-left: 0px;
    padding-top: 20px;
  }
  .profile-settings-card__subscription td:first-child {
    padding-left: 0px;
  }
  .profile-settings-card__subscription td::before {
    content: attr(data-table-title-mobile);
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 10px;
  }
  .profile-settings-card__subscription-stop {
    box-shadow: none;
    margin: 0px;
    margin-left: 10px;
    font-weight: bold;
    text-decoration: underline;
  }
  .profile-settings-card__subscription-stop:hover {
    text-decoration: none;
  }

  .bookmaker-table__row--body {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .bookmaker-table__row--header {
    display: none;
  }
  .bookmaker-table__row {
    margin: 0px;
  }
  .bookmaker-table__col--size-3 {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    flex-basis: 50%;
    padding: 0px;
    margin-bottom: 20px;
  }
  .bookmaker-table__col--size-3::before {
    content: attr(data-table-title-mobile);
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 15px;
  }
  .bookmaker-table__capabilities-list {
    justify-content: flex-start;
  }

  .tariffs-build__card-title {
    text-align: left;
  }
  .tariffs-build__card-row {
    align-items: stretch;
  }
  .tariffs-build__card-row-col {
    width: 100%;
  }
  .tariffs-build__card-row-col .checkbox-btn-label {
    min-width: 0px;
    width: 100%;
    height: 100%;
  }
  .tariffs-build__card-row-col .checkbox-btn-label .checkbox-btn-label__inner {
    padding: 15px 10px;
    height: 100%;
  }

  .tariffs-build__appeal {
    padding: 20px;
  }
  .tariffs-build__appeal-header {
    flex-wrap: wrap;
    margin: 0px;
  }
  .tariffs-build__appeal-header-col--info:first-child {
    width: 50%;
    order: 0;
  }
  .tariffs-build__appeal-header-col--info:last-child {
    width: 50%;
    order: 1;
  }
  .tariffs-build__appeal-header-col--description {
    order: 2;
  }

  .list-benefits {
    display: flex;
    flex-direction: column;
  }
  .p-carousel-container .p-carousel-prev,
  .p-carousel-container .p-carousel-next {
    display: block;
  }
  .p-carousel-indicators {
    display: flex !important;
  }
}

.prematch {
  cursor: default !important;
}

.prematch * {
  cursor: default !important;
}

.logout-message-block {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0%);
  top: 55px;
  width: 250px;
  background-color: #fff;
  color: #000;
  padding: 15px 30px;
  z-index: 1;
  border-radius: 10px;
}
.logout-message-block h3 {
  margin-top: 0;
  margin-bottom: 10px;
}
.logout-message-block__close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAPCAYAAADtc08vAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACmSURBVHgBlZHBDcIwEAQP0UBKyJMnJbgTUgIluAQ6wB2ELy9KcAkpATqANblTTlEUr1daRTl7Jo58lDkZPaFP4dKjIxrKS4dO6Be9k7DtH7eGWaU1+LXeV5P49STEF7yEgr0kO0logS2dk1hv0pjg4LeejM5ZoQJP7tm3wlFniZVcZDl2XK2lmmQPrkoY2BKdpPzu/8oMvgqXaJKDDgb0gz6Ez1AkP3COSKW6nGdUAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
