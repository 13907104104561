.backdrop {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 3;
}

.modal-window {
  position: relative;
  width: 100%;
  max-width: 650px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #000;
  background-color: #f5f5f5;
  padding: 40px 50px;
  border-radius: 7px;
  cursor: default;
  font-size: 16px;

  z-index: 4;
}
.modal-window__close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  background-image: url("../../assets/icon-close.png");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.modal-window__close:hover {
  transform: scale(1.1);
}
.modal-window__title {
  width: 100%;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 15px;
}
.modal-window__sub-title {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
}
.modal-window_row {
  width: 100%;
  margin-bottom: 16px;
  text-align: left;
}
.modal-window_row--center {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal-window_row--right {
  text-align: right;
}
.modal-window_row input:not(input[type="checkbox"]),
.modal-window_row textarea {
  width: 100%;
  height: 45px;
  border-radius: 7px;
  font-size: 15px;
  border: 1px solid lightgray;
  padding: 0px 12px;
}
.modal-window_row textarea {
  height: 80px;
  padding: 12px;
}
.modal-window_col {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-window_col p {
  margin-bottom: 10px;
}
.modal-window a {
  text-decoration: underline;
  color: #000;
}
.modal-window a:hover {
  text-decoration: none;
}

@media (max-width: 1024px) {
  .modal-window {
    padding: 30px 15px;
  }

  .modal-window__close {
    top: 15px;
    right: 15px;
  }
}
